<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeRouteTeamDialog('RouteTeamEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="RouteTeam"
                    :rules="rules"
                    ref="RouteTeamEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="分组名称" prop="name">
                            <el-input v-model="RouteTeam.name" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="图标样式代码" prop="icon">
                            <el-input v-model="RouteTeam.icon" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="所属分类" prop="enterprise_id">
                            <el-select
                                v-model="RouteTeam.model_id"
                                placeholder="选择分类"
                                class="modelc"
                                clearable
                            >
                                <el-option
                                    v-for="model in AllModel"
                                    :key="model.name"
                                    :label="model.name"
                                    :value="model.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>分组名称</span>
                                </template>
                                <span>{{RouteTeam.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>图标样式代码</span>
                                </template>
                                <span>{{RouteTeam.icon}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属分类</span>
                                </template>
                                <span>{{RouteTeam.model_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="RouteTeamDel('RouteTeamEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeRouteTeamDialog('RouteTeamEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('RouteTeamEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('RouteTeamEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeRouteTeamDialog('RouteTeamEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('RouteTeamEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeRouteTeamDialog('RouteTeamEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { route_team_add_request,route_team_del_request,route_team_edit_request } from '@/network/RouteTeam.js'

export default {
    name: 'RouteTeamEditComponent',
    data() {
        return {
            login_loading: false,
            RouteTeam: {
                name: '',
                model_id: '',
                model_name: '',
                icon: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入分组名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '分组详情'
            } else if (this.show_type === 1) {
                return '添加分组'
            } else {
                return '修改分组'
            }
        },
    },
    methods: {
        closeRouteTeamDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitRouteTeamDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.RouteTeamInsert(formName)
            })
        },
        RouteTeamInsert(formName) {
            this.login_loading = true
            route_team_add_request(this.RouteTeam)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitRouteTeamDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.RouteTeam = {
                name: '',
                model_id: '',
                model_name: '',
                icon: '',
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.RouteTeamEdit(formName)
            })
        },
        RouteTeamEdit(formName) {
            this.login_loading = true
            this.RouteTeam.id = this.id
            route_team_edit_request(this.RouteTeam)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitRouteTeamDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.RouteTeam.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        RouteTeamDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    route_team_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeRouteTeamDialog(formName))
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        route_team_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllModel: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        route_team_details_data(newVal) {
            this.RouteTeam = newVal
        },
    },
}
</script>

<style lang='less'>
.modelc {
    width: 100%;
}
</style>