import request from './request'

//权限分组列表
export const route_team_index_request = p => {
    return request({
        method:'GET',
        url:'route_team/index',
        params: p
    })
}

//添加权限分组
export const route_team_add_request = d => {
    return request({
        method:'POST',
        url:'route_team/add',
        data: d
    })
}

//删除权限分组
export const route_team_del_request = id => {
    return request({
        method:'DELETE',
        url:'route_team/del',
        data: {
            id: id
        }
    })
}

//修改权限分组
export const route_team_edit_request = data => {
    return request({
        method:'PUT',
        url:'route_team/edit',
        data
    })
}

//获取权限分组详情
export const route_team_details_request = id => {
    return request({
        method:'GET',
        url:'route_team/details',
        params: {
            id
        }
    })
}